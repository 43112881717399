import React, { Component } from "react";

class Social extends Component {
  render() {
    return (
      <div className="social">
        <a
          href="https://www.linkedin.com/in/krishanpatel"
          target="_blank"
          rel="noopener noreferrer"
          title="linkedin"
        >
          <i className="fa-brands fa-linkedin"></i>
        </a>
        <a
          href="https://www.twitter.com/krishanpate1"
          target="_blank"
          rel="noopener noreferrer"
          title="x-twitter"
        >
          <i className="fa-brands fa-x-twitter"></i>
        </a>
        <a
          href="https://www.instagram.com/krishanpate1"
          target="_blank"
          rel="noopener noreferrer"
          alt="instagram"
          title="instagram"
        >
          <i className="fa-brands fa-instagram"></i>
        </a>
        <a
          href="https://www.facebook.com/krishanpate1"
          target="_blank"
          rel="noopener noreferrer"
          title="facebook"
        >
          <i className="fa-brands fa-facebook-square"></i>
        </a>
      </div>
    );
  }
}

export default Social;
