import React, { Component } from "react";
/*import ReactTypingEffect from "react-typing-effect";*/
import profilepic from "../img/IMG_KP.png";
import Social from "./Social";

class Home extends Component {
  render() {
    return (
      <div className="condiv home">
        <img src={profilepic} alt="Krishan Patel" className="profilepic"></img>
        <h1>Welcome!</h1>
        {/* <ReactTypingEffect
          className="typingeffect"
          text={["I'm Krishan Patel", "a Software Engineer"]}
          speed={100}
          eraseDelay={400}
        /> */}
        <p>Feel free to reach out</p>
        <Social />
      </div>
    );
  }
}

export default Home;
