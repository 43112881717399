import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <div className="condiv">
        <h1 className="subtopic">About Me...</h1>
        <p>
          I'm Krishan Patel, a Software Engineer with experience of working in
          various sectors.
        </p>
        <p>
          My journey in Software Development started in College and the first
          language I worked with was Pascal and some VB.
        </p>

        <p>
          I'm determined, passionate and highly motivated individual that has
          had the privilege of working on various projects and enjoys working &
          collaborating with like-minded individuals.
        </p>

        <p>
          The work I have undertaken has helped me to develop a wide range of
          technical skills which I continually strive to develop further.
        </p>

        <p>
          Outside of work I enjoy travelling, fitness, comedy, films, music and
          learning something new everyday!
        </p>
      </div>
    );
  }
}

export default About;
