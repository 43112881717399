import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
//import '../node_modules/font-awesome/css/font-awesome.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

import "../src/assets/fontawesome-free-6.5.1-web/css/fontawesome.min.css";
import "../src/assets/fontawesome-free-6.5.1-web/css/brands.min.css";
import "../src/assets/fontawesome-free-6.5.1-web/css/solid.min.css";
import "../src/assets/fontawesome-free-6.5.1-web/css/v4-shims.min.css";

/* ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
); */

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
