import React, { Component } from "react";

class Projects extends Component {
  render() {
    return (
      <div className="condiv">
        <h1 className="subtopic">Projects...</h1>
        <p>
          During Univeristy, Industrial Placement and in my career so far I have
          worked on several projects:
        </p>
        <ul className="list-group">
          <li className="list-group-item">
            Final Year Project - Digital Photo Management System
          </li>
          <li className="list-group-item">
            Postal Delivery Mobile Application
          </li>
          <li className="list-group-item">Personal Jukebox Application</li>
          <li className="list-group-item">Student Decision Support System</li>
          <li className="list-group-item">Client Server Chat System</li>
          <li className="list-group-item">Customer Support system</li>
          <li className="list-group-item">Vacancy E-Portal</li>
          <li className="list-group-item">Web Feedback Admin system</li>
          <li className="list-group-item">
            Patient Support Mobile Application
          </li>
          <li className="list-group-item">
            Unifying the processing of international orders
          </li>
          <li className="list-group-item">
            Observability platform for external orders
          </li>
        </ul>
        <br />
        <p>
          As I continue to work on applications the above list will be updated
          so watch this space!
        </p>
      </div>
    );
  }
}

export default Projects;
