import React, { Component } from "react";

class ErrorPage extends Component {
  render() {
    return (
      <div className="condiv">
        <div>
          <h1>404 - Not Found!</h1>
        </div>
      </div>
    );
  }
}

export default ErrorPage;
