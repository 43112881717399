import React, { Component } from "react";
import "./Skills.css";

class Skills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myskills: [
        "C#",
        ".NET Core",
        "HTML/CSS",
        "React",
        "Go-lang",
        "Terraform",
        "SQL",
        "Oracle PL/SQL",
      ],
    };
  }

  render() {
    return (
      <div className="condiv skills">
        <h1 className="subtopic">My Skills...</h1>
        <p>I'm continuously developing my skills in Software Development. </p>
        <p>
          Here are few of the languages/frameworks & tools I've had experience
          with:{" "}
        </p>
        <ul className="ul">
          {this.state.myskills.map((value) => {
            return <li key={value}>{value}</li>;
          })}
        </ul>
      </div>
    );
  }
}

export default Skills;
